import * as React from 'react';
import {
    Box,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemText,
    IconButton,
    Toolbar,
    Typography,
    AppBar
} from '@mui/material'
import { NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import QuickDonate from './QuickDonate';

export default function Header() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const navItems = [
        { name: "Home", link: "/" },
        { name: "Donations", link: "/donate" },
        { name: "Contact", link: "/contact" },
        { name: "Events", link: "/events" },
        { name: "About", link: "/about" },
        { name: "Become a Member", link: "/member" }
    ]

    const theme = createTheme({
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85',
            },
            neutral: {
                main: '#154734',
                darker: '#053e85',
            },
        },
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            <List>
                {navItems.map((navItem) => (
                    <ListItem key={navItem.name}>
                        <NavLink to={navItem.link} >
                            <ListItemText primary={navItem.name} />
                        </NavLink>
                    </ListItem>
                ))}
            </List>

            <Divider />

            <QuickDonate />

        </Box>
    );

    return (
        <div>
            <div>
                <ThemeProvider theme={theme}>
                    <AppBar position="sticky" color='neutral' >
                        <Toolbar>

                            {['left'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="white"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                        onClick={toggleDrawer(anchor, true)}>
                                        <MenuIcon color='white' />
                                    </IconButton>
                                    <Drawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        {list(anchor)}
                                    </Drawer>
                                </React.Fragment>
                            ))}

                            <Typography variant="h6" color={'white'} component="div" >
                                Oakland Islamic Community Center
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>

            </div>
        </div>
    );
}
