
import React from 'react'
import { Box, Grid, Divider } from '@mui/material'
import Announcements from './Announcements';
import Header from './Header';
import SalatTimes from './SalatTimes';
import Footer from './Footer'
import ContactSection from './ContactSection';
import AboutSection from './AboutSection';

import resume from '../utils/oicc_bylaws_2024.pdf'


function Home() {
  return (
    <Box>
      <Header />
      <SalatTimes />
      <div style={{textAlign:'center'}}>
        <a href={resume} target='_blank'>OICC Bylaws</a>
      </div>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AboutSection />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Announcements />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <ContactSection />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
}
export default Home;