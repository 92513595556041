
import * as React from 'react';

export default function EventsSection() {

    return (
        <div  >
            <h2 style={{ textAlign: 'center' }}> Events </h2>
        </div>
    );
}