
import * as React from 'react';
import Header from './Header';
import AboutSection from './AboutSection';
import Footer from './Footer';

export default function About() {
    return (
        <div>
            <Header />
            <AboutSection />
            <Footer />
        </div>
    );
}