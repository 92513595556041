import * as React from 'react';

export default function QuickDonate() {

    let counter = 0;

    const generateId = () => {
        return `ID-${++counter}`;
    }

    const DonateButton = () => {

        const buttonRef = React.useRef(null);
        const buttonId = React.useMemo(() => `ID-${generateId()}`, [])
        React.useEffect(() => {

            const button = window.PayPal.Donation.Button({
                env: 'production',
                hosted_button_id: process.env.REACT_APP_PAYPAL_HOSTED_BUTTON_ID,
                image: {
                    src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
                    alt: 'Donate with PayPal button',
                    title: 'PayPal - The safer, easier way to pay online!',
                }
            });
            button.render(`#${buttonRef.current.id}`); // you can change the code and run it when DOM is ready
        }, []);
        return (
            <div ref={buttonRef} id={buttonId} />
        );
    };

    return (
        <div style={{ width: "50%", margin: 10 }} >
            <DonateButton />
        </div>

    );
}
