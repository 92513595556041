import React from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link, List, ListItem, Stack } from "@mui/material";

const Footer = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
            <List component={Stack} direction='row' spacing={2} >
                <ListItem>
                    <Link href="https://www.facebook.com/OICCmichigan/">
                        <FacebookIcon />
                    </Link>
                    <Link href="https://www.instagram.com/oicc_michigan/">
                        <InstagramIcon />
                    </Link>
                </ListItem>
            </List>
            <p> OICC Copyright 2023, All Rights Reserved. </p>
            <div>
            </div>
        </div>
    );
};
export default Footer;