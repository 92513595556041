import * as React from 'react';
import axios from 'axios'

import {
  Box,
  List,
  Stack,
  ListItem,
  ListItemText,
  Chip,
} from '@mui/material'

const prayers = ['Fajr', 'Dhuhr', '\'Asr', 'Maghrib', '\'Isha']
const baseURL = process.env.REACT_APP_MASJIDAL_API;
const currentTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', hour12: false, minute: 'numeric' })

export default function SalatTimes() {

  const [salats, setSalats] = React.useState(null);
  const [iqamah, setIqamah] = React.useState(null);
  const [jummuah, setJummuah] = React.useState(null);

  const convertTime12to24 = (time12h) => {
  const [time,] = time12h.split("PM");

  let [hours, minutes] = time.split(':');


  if (parseInt(hours) < 10) {
    hours = '0' + hours;
  }

  if (time12h.includes("PM") && parseInt(hours) < 12) {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

console.log(convertTime12to24("12:00PM"))

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      const pickedSalats = (({ fajr, zuhr, asr, maghrib, isha }) => (
        { fajr, zuhr, asr, maghrib, isha }))(response.data.data.salah[0]);

      const pickedIqamahs = (({ fajr, zuhr, asr, maghrib, isha }) => (
        { fajr, zuhr, asr, maghrib, isha }))(response.data.data.iqamah[0]);

      const firstJummuah = (({jummah1}) => ({jummah1}))(response.data.data.iqamah[0])
      setSalats(pickedSalats);
      setIqamah(pickedIqamahs);
      setJummuah(firstJummuah);
    });
  }, []);

  if (!salats) return null;

  console.log(jummuah.jummah1.split(""))

  console.log(convertTime12to24("2:00PM"))

  return (
    <Box sx={{ bgcolor: "transparent" }} >

      <List component={Stack} direction='row' height={15}>
        {prayers.map((prayer) => (
          <ListItem>
            <ListItemText style={{
              justifyContent: 'center',
              display: 'flex',
              justifyItems: 'center'
            }}>
              <b>{prayer}</b>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      
      <List component={Stack} direction="row" height={15}>
        {Object.entries(salats).map(([, time]) =>
          <ListItem >
            <ListItemText style={{
              justifyContent: 'center',
              display: 'flex',
              justifyItems: 'center'
            }}>
              {time}
            </ListItemText>
          </ListItem>
        )}
      </List>
      
      <List component={Stack} direction='row' height={25}>
        {Object.entries(iqamah).map(([, time]) =>
          <ListItem>
            <ListItemText style={{
              justifyContent: 'center',
              display: 'flex',
              justifyItems: 'center'
            }}>
            <Chip label={time} 
                  color={(currentTime > convertTime12to24(time)) ? 'default' : 'success'} 
                  variant={(currentTime > convertTime12to24(time)) ? 'fill' : 'outlined'} 
            />
            </ListItemText>
          </ListItem>
        )}
      </List>
      <List>
        <ListItem>
          <ListItemText style={{
            justifyContent: 'center',
            display: 'flex',
            justifyItems: 'center'
          }}> <b> Jumu'ah : </b> {jummuah.jummah1}</ListItemText>
        </ListItem>
      </List>
    </Box>

  );
}