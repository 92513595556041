
import * as React from 'react';
import { Grid, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email'

export default function ContactSection() {

    const contactItems = [
        { icon: <LocationOnIcon fontSize='large' sx={{color: "#154734"}} />, name: "Address", description: "1400 Orion Rd, Orion Twp, MI 48362" },
        { icon: <LocalPhoneIcon fontSize='large'  sx={{color: "#154734"}} />, name: "Phone", description: "248-701-2903" },
        { icon: <EmailIcon fontSize='large'  sx={{color: "#154734"}} />, name: "Email", description: "michiganoicc@gmail.com" }
    ]
    return (
        <div  >
            <h2 style={{ textAlign: 'center' }}> Contact Us </h2>
            <Grid container align="center" justify="center" alignItems="center" >
                {contactItems.map((contactItem) => (
                    <Grid item xs={4}>
                        {contactItem.icon}
                        <Typography>
                            {contactItem.name}
                        </Typography>
                        {contactItem.name === "Email" ? (<a href='mailto:micchiganoicc@gmail.com'>{contactItem.description}</a>) :

                            (<Typography variant="body2" color="text.secondary">
                                {contactItem.description}
                            </Typography>)
                        }

                    </Grid>
                ))}
            </Grid>
        </div>
    );
}