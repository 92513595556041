import * as React from 'react';
import axios from 'axios';
import CampaignIcon from '@mui/icons-material/Campaign';
import { Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

export default function Announcements() {

    const [announcements, setAnnouncements] = React.useState(null);

    React.useEffect(() => {

        axios({
            url: process.env.REACT_APP_FACEBOOK_POSTS_URL,
            params: {
                access_token: process.env.REACT_APP_FACEBOOK_TOKEN
            },
            method: "get",
        }).then((response) => {
            console.log(response);
            const posts = response.data.data;
            const messages = [];
            posts.forEach((post) => {
                if (post.attachments.data[0].media.image && post.attachments.data[0].description) {
                    const date = new Date(post.created_time).toLocaleDateString();
                    const message = post.attachments.data[0].description;
                    const img = post.attachments.data[0].media.image.src;

                    messages.push({ date: date, message: message, img: img });

                }
            })
            setAnnouncements(messages);
        });

    }, []);

    if (!announcements) return null;

    return (
        <div>

            <h2 style={{ textAlign: 'center' }}> Announcements <CampaignIcon  /> </h2>
            {announcements.map((announcement) => (
                <Accordion style={{ margin: 10 }} sx={{ borderColor: "#154734", borderRadius: "16px" }} >
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ border: 2, borderColor: "#154734", borderRadius: "16px" }}
                    >
                        <Typography style={{ width: "33%" }} align='center'>{announcement.date}</Typography>
                        <Typography style={{ width: "33%" }} sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                        }}>
                            {announcement.message}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ borderRadius: "16px" }}>
                        <Paper style={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} variant="outlined">
                            <img style={{margin: 5}} alt='Announcement' src={announcement.img} width={"25%"} height={"25%"} />
                        </Paper>
                        <Typography style={{ whiteSpace: "pre-line" }}>
                            {announcement.message}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}

        </div>

    );
}
