
import './App.css';
import React from 'react'
import Home from './Components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './Components/About';
import ContactPage from './Components/ContactPage';
import DonatePage from './Components/Donate';
import EventsPage from './Components/EventsPage';



function App() {
  return (
    <div style={{
      width: "100%",
      height: "100vh",
      // backgroundImage: `url(${bg})`,
      backgroundSize: 'cover'
    }}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/donate" element={<DonatePage />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/events" element={<EventsPage />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
