import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Typography } from '@mui/material';

export default function DonatePage() {

    return (
        <div>
            <Header />
            <Typography variant='h2' gutterBottom style={{textAlign: 'center'}}>
                Donate
            </Typography>
            {/* <DonateSection /> */}
            <Footer />
        </div>

    );
}