
import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import ContactSection from './ContactSection';

export default function ContactPage() {

    return (
        <div>
            <Header />
            <ContactSection  />
            <Footer />
        </div>
    );
}