import * as React from 'react';
import EventsSection from './EventsSection';
import Footer from './Footer';
import Header from './Header';

export default function EventsPage() {

    return (
        <div>
            <Header />
            <EventsSection />
            <Footer />
        </div>

    );
}
