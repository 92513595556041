
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function AboutSection() {
    return (
        <div style={{margin: 10}}>
            <h2 style={{ textAlign: 'center' }}> About OICC </h2>
            <div style={{display: 'flex', justifyContent: 'center', justifyItems: 'center'}}>
                <Card style={{width: "75%"}} >
                    <CardMedia
                        sx={{ height: 300 }}
                        image="https://d1bonlq0i2hfej.cloudfront.net/eyJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEyMDAsImhlaWdodCI6ODAwfX0sInVybCI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9wbGFjZXMvQUpERmo0MzhOcjVJTDBXQ09WQl9NbEVnMmlmVzRhSEhkeDVqRm9KNDA5WWhob1ppZ0ZmY3FWWDRSUWZQRWg4ajBSdmNNQjRvWWhJclVWZ1lubnE1OVJCUzZ3YVhUUzJKWWhTX0hqND1zMTYwMC13MTYwMCJ9"
                        title="Oakland Islamic Community Center"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Oakland Islamic Community Center
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            OICC was founded in 2018 for the sole purpose of bringing the people of
                            of the Oakland and Lake Orion communities.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}